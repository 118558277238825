/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { ApiFilled, CheckOutlined, CloseCircleFilled, DashOutlined, DeleteFilled, FileAddFilled, LeftCircleFilled, LoadingOutlined, MailFilled, PlusCircleFilled, PlusOutlined, SearchOutlined, SendOutlined } from "@ant-design/icons";
import { Alert, Button, Col, DatePicker, Divider, Form, Input, message, Modal, Popover, Row, Segmented, Select, Space, Table, Tabs, Typography } from "antd";

import BlankPage, { LayoutModalProps } from "../../framework/blank_page";
import EVAPI from '../../lib/ev_lib/main';
import { config } from '../../config';
import { useNavigate, useParams } from 'react-router-dom';

import { URLQuery } from '../../util/url_queries';
import PackingOrdersPayments from './payments';
import PackingOrdersCargoes from './cargoes';
import Documents from '../../common/documents';
import Comments from '../../common/comments';
import { setClearFormFields } from '../../util/clear_empty';
import SelectionStyledOption from '../../common/SelectionStyledOption';
import { displayName } from '../../lib/ev_lib/builders';
import { convertSystemSettingsArrayDatasetToObject } from '../../lib/ev_lib/data_processors';
import { userSettings } from '../../lib/user_settings';

export default function PackingOrdersForm(props:any) {
  interface PackingOrderProps {
    uuid: string|undefined,
    modules_records_statuses_uuid?: string|null,
    containers_uuid: string|null,
    packages_type?: 'units'|'vehicles'|'packages'|'items'|'lots'|null,
    exporters_uuid?: string|null,
    exporters_reference_id?: string|null,
    exporters_consignees_contacts_uuid?: string|null,
    exporters_notify_party_contacts_uuid?: string|null,
    exporters_forwarding_agent_contacts_uuid?: string|null,
    reference_number?: string|null,
    intermediate_consignees_uuid?: string|null,
    aes_itn?: string|null,
    steam_ship_lines_uuid?: string|null,
    internal_rate?: string|number|null,
    sales_total?: string|number|null,
    create_at?: string|null,
    update_at?: string|null,
    deleted_at?: string|null,
    clear?: string[]|undefined
  }

  interface ContainerProps {
    uuid: string|undefined,
    container_number?: string|null,
    destination_country?: string|null,
    destination_state?: string|null,
    destination_city?: string|null
  }

  interface ExporterProps {
    uuid: string|null,
    notify_parties?:any[]|null
    forwarding_agents?:any[]|null
    consignees?:any[]|null
  }

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  let containerUuid = URLQuery('containerUuid', window)
  
  const [form] = Form.useForm();
  const [mailingForm] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCreatingInvoice, setIsCreatingInvoice] = useState<boolean>(false);
  const [isLoadingContainer, setIsLoadingContainer] = useState<boolean>(false);
  const [isLoadingLastRefNumber, setIsLoadingLastRefNumber] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  
  const [notFound, setNotFound] = useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  const [isLoadingSystemSettings, setIsLoadingSystemSettings] = useState<boolean>(false);
  const [systemSettings, setSystemSettings] = useState<any>(undefined);
  
  const [packingOrdersStatuses, setPackingOrdersStatuses] = useState<any[]>([]);
  const [isLoadingPackingOrdersStatuses, setIsLoadingPackingOrdersStatuses] = useState<boolean>(false);
  
  const [isLoadingOptimizationSettings, setIsLoadingOptimizationSettings] = useState<boolean>(false);
  const [optimizationSettings, setOptimizationSettings] = useState<any>(undefined);
  
  const [exporter, setExporter] = useState<ExporterProps>({
    uuid: null,
    notify_parties: null,
    forwarding_agents: null,
    consignees: null
  });
  const [isLoadingExporter, setIsLoadingExporter] = useState<boolean>(false);

  const [exporters, setExporters] = useState<any[]|null>(null);
  const [isLoadingExporters, setisLoadingExporters] = useState<boolean>(false);
  const [errors, setErrors] = useState<any[]|null>(null);
  
  const [intermediateConsignees, setIntermediateConsignees] = useState<any[]>([]);
  const [isLoadingIntermediateConsignees, setIsLoadingIntermediateConsignees] = useState<boolean>(false);

  const [exportersLastRefNumber, setExportersLastRefNumber] = useState<string|null>(null);
  const [isCheckingRefNumber, setIsCheckingRefNumber] = useState<boolean>(false);
  const [refNumberAvailable, setRefNumberAvailable] = useState<boolean>(false);

  const [relatedEmailingList, setRelatedEmailingList] = useState<any[]>([]);
  
  const [newPackingOrder, setNewPackingOrder] = useState<boolean>(true);
  const [packingOrder, setPackingOrder] = useState<PackingOrderProps>({
    uuid: uuid||undefined,
    containers_uuid: (!uuid && containerUuid) ? containerUuid : null
  });

  const [container, setContainer] = useState<ContainerProps>({
    uuid: containerUuid||undefined,
    destination_country: null,
    destination_city: null
  });

  const [isReady, setIsReady] = useState<boolean>(false);

  const [docsModal, setDocsModal] = useState<{
    open:boolean,
    children?:any[]|null,
    okText?:string,
    onOk?:any,
    onCancel?:any,
    title?:string,
    footer?:any[]
  }>({open: false});

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  let profile:any = localStorage.getItem('profile');
  
  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setIsAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getSystemSettings();
    getExporters();
    getIntermediateConsignees();
    getPackingOrdersStatuses();

    // add a delay to load the screen required prequests
    setTimeout(() => {
      if(uuid) getPackingOrder();
      else if(containerUuid) getContainer();
      setIsReady(true);
    }, 2000)
  }, []);

  useEffect(() => {
    getExportersLastReferenceNumber();
    getExporter();
  }, [packingOrder.exporters_uuid]);

  useEffect(() => {
    checkReferenceNumberAvailability();
  }, [packingOrder.reference_number]);

  /**
   * Rerender the intermediate consingees selection list
   * after processing the container. This was to solve
   * the selection list with items that are inactive
   */

  useEffect(() => {
    getExporters();
    getIntermediateConsignees();
  }, [newPackingOrder])

  useEffect(() => {
    getPackingOrderRelatedEmailingList();
  }, [docsModal])

  const getPackingOrdersStatuses = async () => {
    if(isLoadingPackingOrdersStatuses) return null;
    setIsLoadingPackingOrdersStatuses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.orderDirection = 'ASC';
    EV.module = 'packing orders';
    EV.showAll = true;
    EV.active = 'y';

    var res:any = await EV.getModulesRecordsStatuses();
    
    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          searchable: `${item.name} ${item.description}`,
          value: item.uuid,
          ...item
        });
      });
    }

    setPackingOrdersStatuses(sortedData);

    setIsLoadingPackingOrdersStatuses(false);
    return null;
  }

  // this function return the options list
  // for exporter selection
  const getExporters = async () => {
    if(isLoadingExporters) return null;
    setisLoadingExporters(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getExporters();

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        if(
          item.is_active === 'y'
          || (
            packingOrder.exporters_uuid
            && (item.uuid === packingOrder.exporters_uuid)
          )  
        ) {
          var reformattedUuid = item.uuid.substring(item.uuid.length - 5);

          sortedData.push({
            value: item.uuid,
            searchable: `${item.company_name} ${item.reference_id} ${item.uuid}`,
            label: <SelectionStyledOption
              inactive={item.is_active !== 'y'}
              label={item.company_name}
              tail={`(Ref # ${item.reference_id} | UUID # ...${reformattedUuid})`}
            />
          });
        }
      });
    }

    setExporters(sortedData);

    setisLoadingExporters(false);
    return null;
  }

  const getIntermediateConsignees = async () => {
    if(isLoadingIntermediateConsignees) return null;
    setIsLoadingIntermediateConsignees(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getIntermediateConsignees();

    setIsLoadingIntermediateConsignees(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        if(
          item.is_active === 'y'
          || (
            packingOrder.intermediate_consignees_uuid
            && (item.uuid === packingOrder.intermediate_consignees_uuid)
          )  
        ) sortedData.push({
          searchable: `${item.company_name} ${item.uuid}`,
          value: item.uuid,
          label: item.company_name
        });
      });
    }

    setIntermediateConsignees(sortedData);
    return;
  }

  const getExporter = async () => {
    if(isLoadingExporter || !packingOrder.exporters_uuid) return null;
    setIsLoadingExporter(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = packingOrder.exporters_uuid;

    var res:any = await EV.getExporter();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to exporter\'s data from the server',
      });  
    }

    else if(res.data) {
      // process the consignees as dropdown selection
      var consignees = res.data.consignees;

      if(consignees && consignees.length > 0) {
        var tempConsignees:any[] = [];

        consignees.map((item:any, key:number) => {
          var tags = '';
  
          // buidl the tags
          if(item.tags) {
            try {
              if(typeof item.tags === 'string') {
                item.tags = JSON.parse(item.tags)
              }
  
              item.tags.map((tag:any, tkey:number) => {
                tags += tag + ', '
              })
            }
            catch(e) {
              if(process.env.NODE_MODE === 'development') console.warn(
                'Failed to parse tags', item.tags
              )
            }
          }
          
          var formatUUID = item.contacts_uuid.substring(item.contacts_uuid.length - 5);

          tempConsignees.push({
            ...item,
            value: item.contacts_uuid,
            searchable: `${item.company_name} ${item.uuid} ${item.name_prefix} ${item.first_name} ${item.last_name} ${tags}`,
            label: <SelectionStyledOption
              label={`${item.company_name}`}
              tail={`${displayName(
                item.name_prefix,
                item.first_name,
                item.last_name
              )} | UUID # ...${formatUUID}`}
            />
          })
        })

        consignees = tempConsignees;
      }

      // notify parties
      var notifyParties = res.data.notify_parties;

      if(notifyParties && notifyParties.length > 0) {
        var tempNotifyParties:any[] = [];

        notifyParties.map((item:any, key:number) => {
          var tags = '';
  
          // buidl the tags
          if(item.tags) {
            try {
              if(typeof item.tags === 'string') {
                item.tags = JSON.parse(item.tags)
              }
  
              item.tags.map((tag:any, tkey:number) => {
                tags += tag + ', '
              })
            }
            catch(e) {
              if(process.env.NODE_MODE === 'development') console.warn(
                'Failed to parse tags', item.tags
              )
            }
          }
          
          var formatUUID = item.contacts_uuid.substring(item.contacts_uuid.length - 5);

          tempNotifyParties.push({
            ...item,
            value: item.contacts_uuid,
            searchable: `${item.company_name} ${item.uuid} ${item.name_prefix} ${item.first_name} ${item.last_name} ${tags}`,
            label: <SelectionStyledOption
              label={`${item.company_name}`}
              tail={`${displayName(
                item.name_prefix,
                item.first_name,
                item.last_name
              )} | UUID # ...${formatUUID}`}
            />
          })
        })

        notifyParties = tempNotifyParties;
      }

      // notify parties
      var forwardingAgents = res.data.forwarding_agents;

      if(forwardingAgents && forwardingAgents.length > 0) {
        var tempForwardingAgents:any[] = [];

        forwardingAgents.map((item:any, key:number) => {
          var tags = '';
  
          // buidl the tags
          if(item.tags) {
            try {
              if(typeof item.tags === 'string') {
                item.tags = JSON.parse(item.tags)
              }
  
              item.tags.map((tag:any, tkey:number) => {
                tags += tag + ', '
              })
            }
            catch(e) {
              if(process.env.NODE_MODE === 'development') console.warn(
                'Failed to parse tags', item.tags
              )
            }
          }
          
          var formatUUID = item.contacts_uuid.substring(item.contacts_uuid.length - 5);

          tempForwardingAgents.push({
            ...item,
            value: item.contacts_uuid,
            searchable: `${item.company_name} ${item.uuid} ${item.name_prefix} ${item.first_name} ${item.last_name} ${tags}`,
            label: <SelectionStyledOption
              label={`${item.company_name}`}
              tail={`${displayName(
                item.name_prefix,
                item.first_name,
                item.last_name
              )} | UUID # ...${formatUUID}`}
            />
          })
        })

        forwardingAgents = tempForwardingAgents;
      }

      setExporter({
        ...res.data,
        consignees: consignees,
        notify_parties: notifyParties,
        forwarding_agents: forwardingAgents
      });
    }

    setIsLoadingExporter(false);
    return null;
  }

  const getContainer = async (containerUuid:string|null = null) => {
    if(!packingOrder?.containers_uuid && !containerUuid) return null;
    setIsLoadingContainer(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = packingOrder?.containers_uuid||containerUuid;

    var res:any = await EV.getContainer();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull container data from server',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });  
    }

    else if(res.data) {
      setContainer(res.data);
    }
    
    setIsLoadingContainer(false);
    return null;
  }

  const getExportersLastReferenceNumber = async () => {
    if(isLoadingLastRefNumber || !packingOrder.exporters_uuid) return null;
    setIsLoadingLastRefNumber(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.exporter_uuid = packingOrder.exporters_uuid;

    var res:any = await EV.getExportersPackingOrderLastReferenceNumber();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to get exporter\'s last reference number',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });  
    }

    else if(res.data) {
      setExportersLastRefNumber(res.data.reference_number);
    }

    setIsLoadingLastRefNumber(false);
    return null;
  }

  const checkReferenceNumberAvailability = async () => {
    if(
      !packingOrder.exporters_uuid
      || !packingOrder.reference_number
    ) return null;

    setIsCheckingRefNumber(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.exporter_uuid = packingOrder.exporters_uuid;
    EV.reference_number = packingOrder.reference_number;

    var res:any = await EV.checkPackingOrderReferenceNumberAvailability();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to get exporter\'s last reference number',
      });  
    }
    
    if(res?.code === 200) {
      setRefNumberAvailable(true);
    }

    else {
      setRefNumberAvailable(false);
    }

    setIsCheckingRefNumber(false);
    return null;
  }

  const getPackingOrderRelatedEmailingList = async () => {
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getPackingOrderRelatedEmailingList();
    
    if(res && res.data?.length > 0) {
      var tempArray:any[] = [];

      res.data.map((record:any, key:number) => {
        // clean up the tags
        if(record.tags) {
          record.tags = record.tags.replaceAll("[", "");
          record.tags = record.tags.replaceAll("]", "");
          record.tags = record.tags.replaceAll("\"", "");
        }
        
        var companyName = record.company_name || record.name;

        tempArray.push({
          value: record.email,
          searchable: `${companyName} ${record.first_name} ${record.last_name} ${record.email} ${record.tags} ${record.uuid}`,
          label: <SelectionStyledOption
            layout={2}
            label={record.company_name}
            tail={displayName(
              record.name_prefix,
              record.first_name,
              record.last_name
            ) + (' <' + record.email + '>') as any}
          />
        })
      });

      setRelatedEmailingList(tempArray);
    }
  }

  const getPackingOrder = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getPackingOrder();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to download the packing order data from the server',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });  
    }

    else if(res.data) {
      var packingOrderRes = res.data;

      setPackingOrder(packingOrderRes);

      // get the container
      getContainer(packingOrderRes.containers_uuid);

      form.setFieldsValue(packingOrderRes);
      setNewPackingOrder(false);
    }

    setIsLoading(false);
    return null;
  }

  const processSubmit = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');

    // handle cleared fields
    if(!newPackingOrder) {
      // check clearables
      packingOrder.clear = setClearFormFields(packingOrder)||undefined;
    }
    
    EV = Object.assign(EV, packingOrder)
    
    var res:any = null;
    
    if(packingOrder.uuid && !newPackingOrder) res = await EV.updatePackingOrder();
    else res = await EV.createPackingOrder();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to create or update the packing order',
      });  
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      if(!packingOrder.uuid) setPackingOrder({
        ...packingOrder,
        uuid: res.data.uuid
      })

      setNewPackingOrder(false);
    }

    setIsLoading(false);
    return null;
  }

  const getSystemSettings = async () => {
    if(isLoadingSystemSettings) return null;
    setIsLoadingSystemSettings(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    var res:any = await EV.getSettings();

    if(!res || res.code !== 200) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      }); 
    }

    else if(res.code === 200) {
      var data = res.data;
      
      // build the settings object {uuid, value}
      var tempSettings:any = convertSystemSettingsArrayDatasetToObject(data);

      setSystemSettings({
        tax_rate: (tempSettings?.default_tax_rate) ? Number(tempSettings?.default_tax_rate) : undefined,
        remittince: tempSettings?.invoice_remittince
      })
    }

    setIsLoadingSystemSettings(false);
    return null;
  }

  const createInvoice = async () => {
    if(isCreatingInvoice) return null;
    setIsCreatingInvoice(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');

    EV.reference_number = packingOrder.exporters_reference_id||'';

    if(packingOrder.reference_number) {
      if(EV.reference_number.length > 0) {
        EV.reference_number += '-';
      }

      EV.reference_number += packingOrder.reference_number;
    }

    EV.exporters_uuid = packingOrder.exporters_uuid;
    
    EV = Object.assign(EV, systemSettings);
    
    var res:any = await EV.createInvoice();
    
    if(!res || res.status === 'fail') {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });

      setIsCreatingInvoice(false);
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
      setIsCreatingInvoice(false);
    }

    else if(res.code === 200) {
      linkPackingOrder(res?.data?.uuid);
    }

    return;
  }

  const linkPackingOrder = async (uuid:string) => {
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.invoices_uuid = uuid;
    EV.packing_orders_uuid = packingOrder.uuid;

    var res:any = await EV.linkInvoiceRelatedPackingOrder();
    setIsCreatingInvoice(false);
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to link the selected packing order',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en,
      });  
    }

    else {
      navigate(
        '/accounting/invoices/invoices/form/' + uuid, 
        {replace: true}
      );
    }

    return;
  }

  const _renderRefNumberCheck = () => {
    if(
      !packingOrder.reference_number
      || packingOrder.reference_number.length < 1
    ) {
      return <DashOutlined style={{color: 'gray', fontSize: 20}} />
    }

    else if(
      !newPackingOrder
      || (newPackingOrder && refNumberAvailable)
    ) {
      return <CheckOutlined style={{color: 'green', fontSize: 20}} />
    }

    else if(isCheckingRefNumber) {
      return <LoadingOutlined style={{color: 'gray', fontSize: 20}} />
    }

    else {
      return <CloseCircleFilled style={{color: 'red', fontSize: 20}} />
    }
  }

  const _renderExportersLastReferenceNumberPlaceholder = () => {
    if(!packingOrder.exporters_uuid) {
      return `Select an exporter`
    }

    else if(exportersLastRefNumber) {
      return `Last reference number used: ${exportersLastRefNumber}`
    }

    else {
      return `This exporter doesn't have any previouslly set reference numbers`
    }
  }

  const emailDoc = async (
    type:string, replyTo:any, to:any, cc:any
  ) => {
    // initiate the library
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.to = to;
    EV.cc = cc;
    EV.replyTo = replyTo;
    EV.uuid = packingOrder.uuid;
    // call the function by the type
    var res:any = null;

    switch(type) {
      case 'hbl':
        res = await EV.emailPackingOrderHouseBillOfLading();
      break;
      case 'custom-clearance':
        res = await EV.emailPackingOrderCustomClearance();
      break;
      case 'dock-receipt':
        res = await EV.emailPackingOrderDocReceipt();
      break;
      case 'house-hold-goods':
        res = await EV.emailPackingOrderHouseHoldGoods();
      break;
    }

    // handle the results
    if(res && res.status === 'success') {
      mailingForm.resetFields();

      setDocsModal({open: false});
      
      messageApi.open({
        type: 'success',
        content: res.message?.en
      });
    }
    else if(res.errors) {
      setLayoutModal({
        open: true,
        title: 'Oops!',
        children: [<>
          <div><Typography.Text>Errors submitting your data:</Typography.Text></div>
          <ul>{res.errors.map((error:any, key:number) => {
            if(typeof error === 'object') {
              error = error.en
            }

            return <li>{error}</li>;
          })}</ul>
        </>],
        footer: [<Button
          type={'default'}
          children={'Ok'}
          onClick={() => setLayoutModal({open: false})}
        />]
      })
    }

    return null;
  }

  const previewDocument = async (type:string) => {
    // initiate the library
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = packingOrder.uuid;
    
    var res:any = null;

    switch(type) {
      case 'hbl':
        res = await EV.generatePackingOrderHouseBillOfLadingPDF();
      break;
      case 'custom-clearance':
        res = await EV.generatePackingOrderCustomClearancePDF();
      break;
      case 'dock-receipt':
        res = await EV.generatePackingOrderDocReceiptPDF();
      break;
      case 'house-hold-goods':
        res = await EV.generatePackingOrderHouseHoldGoodsPDF();
      break;
    }
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to generate document review',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en,
      });  
    }

    else {
      const response = await fetch(res.document_path, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
        }
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      } else {
        messageApi.open({
          type: 'error',
          content: 'Failed to fetch the document',
        });
      }
    }

    return;
  }

  const handleDocsModal = (type:'hbl'|'custom-clearance'|'dock-receipt'|'house-hold-goods') => {
    var title = 'Unset';
    var previewAction:any = () => previewDocument(type);
    var replyTo:any = null;
    var to:any = null;
    var cc:any = null;

    var profileEmail = null;

    var profile:any = localStorage.getItem('profile');

    if(profile) {
      try {
        if(typeof profile === 'string') {
          profile = JSON.parse(profile);
        }

        profileEmail = profile.email;
      }
      catch(e) {
        if(process.env.NODE_MODE === 'development') {
          console.error(
            'Failed to parse user profile',
            e
          );
        }
      }
    }

    var replyToOptions = [
      { label: 'System Default', value: null },
      { label: `Me - ${profileEmail}`, value: profileEmail }
    ];

    var children = [
      <Form
        form={mailingForm}
        layout="horizontal"
        labelCol={{span: 4}}
        style={{minWidth: '100%'}}
      >
        <Form.Item
          label={'Reply to'} 
          name={'reply_to'}
        >
          <Select
            placeholder={'Select one'}
            onChange={(value) => replyTo = value}
            options={replyToOptions}
          />
          <div style={{marginTop: 10}}>
            Recommended to leave this to the default value unless
            expected to get a response from any of the receivers.
          </div>
        </Form.Item>
        <Form.Item
          label={'To'} 
          name={'to'}
          required
        >
          <Select 
            mode="tags"
            placeholder={'Select one'}
            onChange={(value) => to = value}
            options={relatedEmailingList}
            tokenSeparators={[',']}
          />
        </Form.Item>
        <Form.Item
          label={'CC'} 
          name={'cc'}
        >
          <Select 
            mode="tags"
            placeholder={'Select one'}
            onChange={(value) => cc = value}
            options={relatedEmailingList}
            tokenSeparators={[',']}
          />
        </Form.Item>
      </Form>
    ];

    setDocsModal({
      open: true,
      title: title,
      onCancel: () => {
        mailingForm.resetFields();
        setDocsModal({open: false});
      },
      children: children,
      footer: [
        <Button
          type='default'
          children='Cancel'
          onClick={() => {
            mailingForm.resetFields();
            setDocsModal({open: false});
          }}
        />,
        <Button
          type='default'
          children='Preview PDF'
          onClick={previewAction}
        />,
        <Button
          type='primary'
          children='Email'
          icon={<SendOutlined />}
          onClick={() => emailDoc(type, replyTo, to, cc)}
        />,
      ]
    })
  }

  const deletePackingOrder = async () => {
    if(isDeleting) return;
    setIsDeleting(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = packingOrder?.uuid;
    
    var res:any = await EV.deletePackingOrder();
    // var res:any = false;
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }
  
  return <BlankPage
    unauthorized={!isAuthorized}
    notFound={notFound}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(isAuthorized && !notFound) && <>
        <Button
          type={'primary'}
          children={'Create Invoice'}
          loading={isCreatingInvoice}
          onClick={() => {
            if(
              !packingOrder.uuid
              || !packingOrder.exporters_uuid
            ) {
              setLayoutModal({
                key: 'invoice-creation-error',
                open: true,
                footer: [<Button 
                  children={'Cancel'}
                  onClick={() => setLayoutModal({open: false})}
                />],
                title: 'Create Packing Order Invoice',
                children: [<>
                  <Alert
                    showIcon
                    type={'error'}
                    message={'Must select an exporter to create an invoice'}
                  />
                </>]
              })

              return;
            }

            else {
              setLayoutModal({
                key: 'invoice-creation-confirmation',
                open: true,
                onOk: () => {
                  createInvoice();
                  setLayoutModal({open: false});
                },
                okText: 'Confirm & Proceed',
                onCancel: () => setLayoutModal({open: false}),
                title: 'Create Packing Order Invoice',
                children: [<>
                  <Typography.Paragraph>
                    <Typography.Text>
                      The system will generate a new invoice with the current packing 
                      order and selected exporter linked to it automatically. Do you 
                      want to proceed?
                    </Typography.Text>
                  </Typography.Paragraph>
                </>]
              })

              return;
            }
          }}
          disabled={(!packingOrder.uuid || !packingOrder.exporters_uuid) && newPackingOrder}
        />
        <Button
          type={'default'}
          children={'HBL'}
          onClick={() => handleDocsModal('hbl')}
          disabled={!packingOrder.uuid && newPackingOrder}
        />
        <Button
          type={'default'}
          children={'Custom Clearance'}
          onClick={() => handleDocsModal('custom-clearance')}
          disabled={!packingOrder.uuid && newPackingOrder}
        />
        <Button
          type={'default'}
          children={'Doc Receipt'}
          onClick={() => handleDocsModal('dock-receipt')}
          disabled={!packingOrder.uuid && newPackingOrder}
        />
        <Button
          type={'default'}
          children={'House Hold Goods'}
          onClick={() => handleDocsModal('house-hold-goods')}
          disabled={!packingOrder.uuid && newPackingOrder}
        />
      </>}
      {(isAuthorized && !notFound && packingOrder.uuid && !newPackingOrder) && <>
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deletePackingOrder();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
    rightSideComponent={() => {
      return <Comments 
        module={'packing orders'}
        moduleUuid={packingOrder.uuid}
      />
    }}
    blockContent={(isReady && !packingOrder?.uuid && !containerUuid) && <div 
      style={{padding: '10px 0px'}}
    >
      <Alert
        message="Error"
        description="Viewing this screen requires a preselection of a container. Please go back and try again."
        type="error"
      />
    </div>}
    isLoading={!isReady}
  >
    {contextHolder}

    <Modal
      title={docsModal.title}
      open={docsModal?.open}
      onOk={docsModal.onOk}
      onCancel={docsModal.onCancel}
      okText={docsModal.okText}
      children={docsModal.children}
      footer={docsModal.footer}
    />

    <Typography.Title 
      level={1}
      children={(packingOrder?.uuid) ? 'Manage Packing Order' : 'New Packing Order'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={packingOrder?.uuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      initialValues={packingOrder}
      onFinish={processSubmit}
    >
      <Form.Item
        label={'Container'} 
        name={'containers_uuid'}
      >
        <Input 
          placeholder={'Auto generated by the system'}
          value={`${packingOrder?.containers_uuid} (# ${container?.container_number||'-'})`}
          disabled={true}
        />
      </Form.Item>

      <Form.Item
        label={'Packages Type'} 
        name={'packages_type'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          onChange={(value) => setPackingOrder({
          ...packingOrder,
          packages_type: value||''
        })}
          options={[
            { value: '', label: 'None' },
            { value: 'units', label: 'Units' },
            { value: 'vehicles', label: 'Vehicles' },
            { value: 'packages', label: 'Packages' },
            { value: 'items', label: 'Items' },
            { value: 'lots', label: 'LOTs' }
          ]}
        />
      </Form.Item>

      <div style={{marginBottom: 15}} />

      <Segmented
        block
        style={{textTransform: 'capitalize'}}
        options={packingOrdersStatuses}
        value={packingOrder.modules_records_statuses_uuid||0}
        name={'modules_records_statuses_uuid'}
        onChange={(value) => setPackingOrder({
          ...packingOrder,
          modules_records_statuses_uuid: value.toString()
        })}
      />

      <div style={{marginBottom: 15}} />

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label={'Exporter'} 
            name={'exporters_uuid'}
            required
          >
            <Select 
              allowClear
              showSearch
              status={(!packingOrder.exporters_uuid) ? 'error' : undefined}
              placeholder={'Select one'}
              onChange={(value) => setPackingOrder({
                ...packingOrder,
                exporters_uuid: value
              })}
              filterOption={(input, option) =>
                (option?.searchable ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={exporters||[]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Reference #'} 
            name={'reference_number'}
          >
            <Input 
              placeholder={_renderExportersLastReferenceNumberPlaceholder()}
              onChange={(e) => setPackingOrder({
                ...packingOrder,
                reference_number: e.target.value
              })}
              addonAfter={_renderRefNumberCheck()}
              disabled={!packingOrder.exporters_uuid}
            />
          </Form.Item>
        </Col>
      </Row>
      
      <Form.Item
        label={'Consignee'} 
        name={'exporters_consignees_contacts_uuid'}
      >
        <Select 
          allowClear
          showSearch
          placeholder={'Select one'}
          defaultValue={packingOrder?.exporters_consignees_contacts_uuid||null}
          onChange={(value) => setPackingOrder({
            ...packingOrder,
            exporters_consignees_contacts_uuid: value||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={exporter?.consignees||[]}
        />
      </Form.Item>
      
      <Form.Item
        label={'Notify Party'} 
        name={'exporters_notify_party_contacts_uuid'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          defaultValue={packingOrder?.exporters_notify_party_contacts_uuid||null}
          onChange={(value) => setPackingOrder({
            ...packingOrder,
            exporters_notify_party_contacts_uuid: value||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={exporter?.notify_parties||[]}
        />
      </Form.Item>
      
      <Form.Item
        label={'Forwarding Agents'} 
        name={'exporters_forwarding_agent_contacts_uuid'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          defaultValue={packingOrder?.exporters_forwarding_agent_contacts_uuid||null}
          onChange={(value) => setPackingOrder({
            ...packingOrder,
            exporters_forwarding_agent_contacts_uuid: value||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={exporter?.forwarding_agents||[]}
        />
      </Form.Item>

      <div className={'compact-view-hide'} style={{marginBottom: 15}} />

      <Form.Item
        label={'Intermediate Consignee'} 
        name={'intermediate_consignees_uuid'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          defaultValue={packingOrder?.intermediate_consignees_uuid||null}
          onChange={(value) => setPackingOrder({
            ...packingOrder,
            intermediate_consignees_uuid: value||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={intermediateConsignees||[]}
        />
      </Form.Item>

      <Form.Item
        label={'AES ITN'} 
        name={'aes_itn'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setPackingOrder({
            ...packingOrder,
            aes_itn: e.target.value
          })}
        />
      </Form.Item>

      <Typography.Title 
        level={2} 
        children={'Financials'} 
        className={'compact-view-hide'}
      />
      
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label={'Internal Rate'} 
            name={'internal_rate'}
          >
            <Input 
              placeholder={'0.00'}
              addonAfter={'USD'}
              onChange={(e) => setPackingOrder({
                ...packingOrder,
                internal_rate: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Sales Total'} 
            name={'sales_total'}
          >
            <Input 
              placeholder={'0.00'}
              addonAfter={'USD'}
              onChange={(e) => setPackingOrder({
                ...packingOrder,
                sales_total: e.target.value
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      {!newPackingOrder && <>
        <PackingOrdersPayments
          showTitle={!userSettings('compact_view')}
          packingOrderUuid={packingOrder.uuid}
          allowEdit={isAuthorized}
        />

        <PackingOrdersCargoes 
          showTitle={!userSettings('compact_view')}
          showView
          showEdit
          showUnlink
          showLinkButton
          showBulkUpdate
          packingOrderUuid={packingOrder.uuid}
          exporterUuid={packingOrder.exporters_uuid}
          containerDestination={{
            country: container?.destination_country,
            state: container?.destination_state,
            city: container?.destination_city
          }}
        />
        
        <Documents
          purpose={'packing order'}
          purposeUuid={packingOrder?.uuid}
          allowManage
          allowUpload
          title={!userSettings('compact_view') ? 'Images and Documents' : undefined}
          optimization={{
            max_width: optimizationSettings?.image_optimization_max_width,
            quality: optimizationSettings?.image_optimization_quality_value
          }}
        />
      </>}

      <div style={{marginBottom: 15}} />

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isLoading}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  </BlankPage>
}