/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */
import { Children, useEffect, useState } from 'react';

import { Button, Card, Col, Dropdown, Layout, Menu, MenuProps, message, Modal, Row, Skeleton, Space, theme, Typography } from "antd";
import { AppstoreOutlined, ProductOutlined, ClusterOutlined, ContactsOutlined, SettingOutlined, AppstoreAddOutlined, VerticalRightOutlined, VerticalLeftOutlined, UserOutlined, DollarOutlined, InfoCircleOutlined, BulbOutlined, SmileOutlined, BulbTwoTone, SmileTwoTone, AppstoreTwoTone } from '@ant-design/icons';

import Sider from "antd/es/layout/Sider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import packageManager from '../../package.json';
import NotFound from './states/not_found';
import Unauthorized from './states/unauthorized';
import { userSettings } from '../lib/user_settings';
import { displayName } from '../lib/ev_lib/builders';
import { gold, green, orange } from '@ant-design/colors';
import { MFABanner } from '../auth/mfa';

export interface LayoutModalProps {
  key?:string,
  open:boolean,
  okText?:string,
  onOk?:any,
  cancelText?:string,
  onCancel?:any,
  title?:string,
  children?:any[],
  footer?:any[]
}

export default function BlankPage(props:any) {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const [profile, setProfile] = useState<any>(undefined);
  const [hideRightSidePanel, setHideRightSidePanel] = useState<boolean>(
    userSettings('hide_right_side_menu_by_default') === 'y' ? true : false
  );

  const [companySettings, setCompanySettings] = useState<any>(undefined);

  useEffect(() => {
    let storedProfile:any = localStorage.getItem('profile');
    
    try {
      if(typeof storedProfile !== 'object') {
        storedProfile = JSON.parse(storedProfile);
      }

      setProfile(storedProfile);
      console.log(storedProfile)
    }
    catch(e) {
      console.warn(e)
    };

    // load and parse company settings from memory
    try {
      var initialCompanySettings:any = localStorage.getItem('company_settings');

      if(typeof initialCompanySettings !== 'object') {
        initialCompanySettings = JSON.parse(initialCompanySettings);
        setCompanySettings(initialCompanySettings);
      }
    }
    catch(e) {
      console.warn(e)
    }
  }, []);

  // load the profile to process the side menu links
  let globalSettings:string|any[]|undefined|null = localStorage.getItem('dispatchGlobalSettings');
  let globalSettingsObj:any = {}

  if(globalSettings) try {
    if(typeof globalSettings === 'string') {
      globalSettings = JSON.parse(globalSettings);
    }

    if(
      globalSettings
      && typeof globalSettings === 'object' 
      && globalSettings.length > 0
    ) {
      globalSettings.map((setting:{uuid:any, value:any}, key:number) => {
        globalSettingsObj[`${setting.uuid}`] = setting.value
      })
    }
  }
  catch(e) {
    if(process.env.REACT_APP_MODE === 'development') {
      console.warn(
        'Failed to parse the global settings'
      );
    }
  }

  const generalMenu = [
    {
      key: '/', 
      label: 'Dashboard', 
      icon: <AppstoreOutlined /> 
    }
  ];

  const managementMenu:any = profile?.type === 'management' ? [
    {
      key: 'drop-off',
      label: 'Drop Off',
      icon: <ProductOutlined />,
      children: [
        { key: '/drop-off/appointments', label: 'Appointments' },
      ]
    },
    {
      key: 'inventory',
      label: 'Inventory',
      icon: <AppstoreAddOutlined />,
      children: [
        { key: '/inventory/cargoes', label: 'Cargoes' },
      ]
    },
    {
      key: 'containers',
      label: 'Containers',
      icon: <ClusterOutlined />,
      children: [
        { key: '/containers/packing-plans', label: 'Packing Plans' },
        { key: '/containers/containers', label: 'Containers' }
      ]
    },
    {
      key: 'accounting',
      label: 'Accounting',
      icon: <DollarOutlined />,
      children: [
        { key: '/accounting/invoices/invoices', label: 'Invoices' },
      ]
    },
    {
      key: 'address-book',
      label: 'Address Book',
      icon: <ContactsOutlined />,
      children: [
        { key: '/address-book', label: 'All Contacts' },
        { key: '/exporters', label: 'Exporters' },
        { key: '/intermediate-consignees', label: 'Intr. Consignees' },
      ]
    },
    {
      key: 'system',
      label: 'System',
      icon: <SettingOutlined />,
      children: [
        { key: '/system/settings', label: 'Settings' },
      ]
    }
  ] : [];

  const exporterMenu:any = profile?.type === 'exporter' ? [
    {
      key: 'drop-off',
      label: 'Drop Off',
      icon: <ProductOutlined />,
      children: [
        { key: '/drop-off/appointments', label: 'Appointments' },
      ]
    },
    {
      key: 'inventory',
      label: 'Inventory',
      icon: <AppstoreAddOutlined />,
      children: [
        { key: '/inventory/cargoes', label: 'Cargoes' },
      ]
    },
    {
      key: 'containers',
      label: 'Containers',
      icon: <ClusterOutlined />,
      children: [
        { key: '/containers/packing-plans', label: 'Packing Plans' },
        { key: '/containers/containers', label: 'Containers' }
      ]
    },
    (!profile?.limited_access || profile.limited_access === 'n') && {
      key: 'accounting',
      label: 'Accounting',
      icon: <DollarOutlined />,
      children: [
        { key: '/accounting/invoices/invoices', label: 'Invoices' },
      ]
    },
  ] : [];

  const helpMenu:any = [
    {
      key: '/external/feedback',
      label: 'Feedback',
      icon: <SmileTwoTone twoToneColor={green[5]} />,
    },
    {
      key: '/external/help',
      label: 'Help',
      icon: <BulbTwoTone twoToneColor={gold[6]} />,
    },
    {
      key: '/system/about',
      label: 'About',
      icon: <InfoCircleOutlined />,
    }
  ]

  const isDarkMode = localStorage.getItem('isDarkMode') || false;

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const headerStyle = {
    background: colorBgContainer,
    paddingVertical: 10,
    lineHeight: 'unset',
    height: 'unset',
    paddingInline: 0
  }
  
  // handle messageApi
  let  messageApiKeys:any[] = [];

  if(props.messageApi) {
    if(messageApiKeys.includes(props.messageApi.key)) return null;

    messageApiKeys.push(props.messageApi.key);
    messageApi.open(props.messageApi);

    setTimeout(() => {
      var index = messageApiKeys.indexOf(props.messageApi.key);
      messageApiKeys = messageApiKeys.splice(index, 1);
    });
  }

  const _renderScreenContent = () => {
    if(props.isLoading) {
      return <Skeleton active />
    }
    else if(props.notFound) return <NotFound />
    else if(props.unauthorized) return <Unauthorized />
    else if(props.blockContent) return props.blockContent;
    else if(props.children) return props.children;
    else return <NotFound />
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a target="_self" rel="noopener noreferrer" href={"/system/settings/users/view/" + profile?.uuid || 'me'}>
          {profile?.company_name||'/** Error'}
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a target="_self" rel="noopener noreferrer" href={"/system/settings/users/view/" + profile?.uuid || 'me'}>
          Profile
        </a>
      ),
    },
    {
      type: 'divider'
    },
    {
      key: '3',
      label: (
        <a target="_self" rel="noopener noreferrer" href={"/login?logout=true"}>
          Logout
        </a>
      ),
    },
  ];

  return (
    <Layout style={{height: '100vh'}}>
      {(props.showSideMenu) && <Sider 
        className="hide-scrollbar"
        style={{
          overflow: 'scroll',
          background: colorBgContainer,
          padding: '10px 0'
        }}
      >
        <div style={{marginLeft: 20, width: 150}}>
          {(companySettings?.company_logo_small) 
            ? <img 
                src={`${localStorage.getItem('target_domain')}storage/company/${companySettings?.company_logo_small}`} 
                height={40}
                style={{height: 'auto', maxWidth: '100%'}}
              /> 
            : <img 
                src={require('../framework/assets/logo.png')} 
                height={40}
                style={{height: 'auto', maxWidth: '100%'}} 
              />
          }
        </div>

        <Menu
          mode='vertical'
          theme={isDarkMode ? 'dark' : 'light'}
          style={{
            border: 0, 
            background: colorBgContainer,
            marginTop: 15
          }}
          items={[
            ...generalMenu,
            ...managementMenu,
            ...exporterMenu,
            ...helpMenu
          ]}
          onClick={(e:any) => {
            if(e.key === '/external/feedback') {
              window.open(globalSettingsObj[`feedback-uri`])
              return;
            }
            else if(e.key === '/external/help') {
              window.open(globalSettingsObj[`help-uri`])
              return;
            }

            navigate(`${e.key}`,{replace: true})
          }}
        />

        <div style={{padding: 20}}>
          <Typography.Text style={{fontSize: '9pt'}} type={'secondary'}>
            Release {packageManager.version}
          </Typography.Text>
        </div>
      </Sider>}
      <Layout.Content
        id={'layout-content-container'}
        className="hide-scrollbar"
        style={{
          flex: 3,
          overflow: 'scroll',
          background: colorBgContainer,
          padding: props.padded ? 10 : 0
        }}
      >
        {(props.showDefaultHeader) && <Layout.Header
          style={headerStyle}
        >
          
        </Layout.Header>}
        <Layout.Header
          style={headerStyle}
        >
          {props.customHeader}

          <div style={{float: 'right'}}>
            <Space>
              <Dropdown menu={{ items }} placement="topLeft" arrow>
                <Button
                  type={'text'}
                  icon={<UserOutlined />}
                  children={displayName(profile?.name_prefix, profile?.first_name, profile?.last_name)}
                  onClick={() => null}
                />
              </Dropdown>
              
              {(hideRightSidePanel) && <> 
                <Button
                  type={'text'}
                  icon={<VerticalRightOutlined />}
                  children={'Open Side Panel'}
                  onClick={() => setHideRightSidePanel(!hideRightSidePanel)}
                /> 
              </>}
            </Space>
          </div>
        </Layout.Header>
        
        {contextHolder}
        
        {(props.modal) && <Modal
          title={props.modal?.title}
          open={props.modal?.open}
          onOk={props.modal?.onOk}
          okText={props.modal?.okText}
          onCancel={props.modal?.onCancel}
          cancelText={props.modal?.cancelText}
          children={props.modal?.children}
          footer={props.modal?.footer}
        />}

        <div style={{marginTop: 50}}>
          <MFABanner />
        </div>
        
        <div style={{paddingBottom: 80}}>
          {_renderScreenContent()}
        </div>
        
        {(props.showDefaultFooter) && <Layout.Footer>
          
        </Layout.Footer>}
        {(props.customFooter) && <Layout.Footer>
          {props.customFooter}
        </Layout.Footer>}
      </Layout.Content>
      
      {(props.rightSideComponent && !hideRightSidePanel) && <Layout.Content
        style={{
          flex: 1,
          overflow: 'scroll',
          background: colorBgContainer,
          padding: props.padded ? 10 : 0,
        }}
      >
        <Space>
          <Button
            type={'default'}
            iconPosition={'end'}
            icon={<VerticalLeftOutlined />}
            children={'Close Panel'}
            onClick={() => setHideRightSidePanel(!hideRightSidePanel)}
          />
        </Space>

        {props.rightSideComponent()}
      </Layout.Content>}
    </Layout>
  )
}