/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect, Children } from 'react';

import { AuditOutlined, CheckOutlined, DownOutlined, ExpandAltOutlined, ExportOutlined, EyeOutlined, FormOutlined, LoadingOutlined, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Dropdown, Form, Input, Menu, message, ModalProps, Pagination, Row, Segmented, Select, Space, Table, Tag, Typography } from "antd";
import TableColumnsCuztomization, { TableColumnsProps as SelectedTableColumnProps} from '../../../common/TableColumnsCuztomization';

import BlankPage from "../../../framework/blank_page";
import EVAPI from '../../../lib/ev_lib/main';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { URLQuery } from '../../../util/url_queries';
import TableColumnSortIcon from '../../../common/TableColumnSortIcon';
import { resortTable } from '../../../lib/table_sorting';
import { blue, cyan, gold, green, red, yellow } from '@ant-design/colors';
import { userSettings } from '../../../lib/user_settings';
import { TableRowSelection } from 'antd/es/table/interface';

export default function InvoicesListing(props:any) {
  interface AdvanceFiltersProps {
    exporters_uuid?: string|null|undefined,
    exportersUuids?:string[]|null|undefined
    reference_number?: string|null|undefined,
    status?: string|null|undefined,
    issueDateFrom?: string|string[],
    issueDateFromObj?: any,
    issueDateTo?: string|string[],
    issueDateToObj?: any,
    dueDateFrom?: string|string[],
    dueDateFromObj?: any,
    dueDateTo?: string|string[],
    dueDateToObj?: any,
  }

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  let profile:any = localStorage.getItem('profile');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [isManagement, setIsManagement] = useState<boolean>(false);
  
  const [allowEdit, setAllowEdit] = useState<boolean>(false);
  const [allowCreateNew, setAllowCreateNew] = useState<boolean>(false);
  const [selectedInvoices, setSelectedInvoices] = useState<React.Key[]>([]);

  const [screenModal, setScreenModal] = useState<ModalProps>({open: false})

  const [showSideMenu, setShowSideMenu] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [containersStatusesUuid, setContainersStatusesUuid] = useState<any>(null);

  const [toggleAdvancedFilters, setToggleAdvancedFilters] = useState<boolean>(false);
  const [advanceFilters, setAdvanceFilters] = useState<AdvanceFiltersProps>({
    status: 'all'
  });

  const [invoices, setInvoices] = useState<any[]>([]);
  const [invoicesCount, setInvoicesCount] = useState<number>(0);
  const [invoicesPerPage, setInvoicesPerPage] = useState<number>(15);
  const [invoicesCurrentPage, setInvoicesCurrentPage] = useState<number>(1);
  
  const [exporters, setExporters] = useState<any[]|null>(null);
  const [isLoadingExporters, setisLoadingExporters] = useState<boolean>(false);

  const [selectedColumns, setSelectedColumns] = useState<string[]>();

  const tableColumnsList:{id:string, label:string, default?:boolean}[] = [
    { id: 'uuid', label: 'UUID', default: true},
    { id: 'reference_number', label: 'Ref #', default: true},
    { id: 'exporters_company_name', label: 'Exporter', default: true},
    { id: 'issue_date', label: 'Issue Date', default: true},
    { id: 'due_date', label: 'Due Date', default: true},
    { id: 'sub_total', label: 'Sub Total', default: true},
    { id: 'tax_total', label: 'Tax', default: true},
    { id: 'grand_total', label: 'Total', default: true},
    { id: 'balance', label: 'Balance', default: true},
  ];

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(['management'].includes(profile.type)) {
        setAllowEdit(true);
        setAllowCreateNew(true);
        setIsManagement(true);
        setAuthorized(true);
      }
      
      else if (
        profile.type === 'exporter'
        && (
          !profile.limited_access
          || profile.limited_access === 'n'
        )
      ) {
        setAuthorized(true);
      }

      else {
        setAuthorized(false);
      }
    }
    catch(e) {
      console.warn(e)
    };

    // set the default ResPerPage
    if(localStorage.getItem('prefInvoicesListingResPerPage')) {
      setInvoicesPerPage(Number(localStorage.getItem('prefInvoicesListingResPerPage')));
    }

    processPrefTableColumns();
    getExporters();
    getInvoices();
  }, []);
  
  useEffect(() => {
    getInvoices();
  }, [searchTerm, advanceFilters, invoicesPerPage, invoicesCurrentPage]);

  const processPrefTableColumns = () => {
    var columns:string[] = [];
    var storedColumnsString:string|null = localStorage.getItem('prefInvoicesListingTableViewColumns');
    var storedColumnArray:string[] = [];

    if(storedColumnsString) {
      storedColumnArray = storedColumnsString.split(',');
    }

    var tempColumns:string[] = [];

    tableColumnsList.map((column:SelectedTableColumnProps, key:number) => {
      if(column.default) tempColumns.push(column.id);
    });

    if(storedColumnArray.length > 0) {
      columns = storedColumnArray;
    }

    else {
      columns = tempColumns;
    }

    setSelectedColumns(columns);
    return;
  }

  const getExporters = async () => {
    if(isLoadingExporters) return null;
    setisLoadingExporters(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getExporters();

    setisLoadingExporters(false);

    var data = res?.data;
    var sortedData:any = [];

    if(data?.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${(!item.is_active || item.is_active !== 'y') ? '[Inactive] ' : ''}${item.company_name} (${item.reference_id||'-'} / ${item.uuid})`
        });
      });
    }

    setExporters(sortedData);
  }

  const getInvoices = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.searchTerm = searchTerm;

    EV = Object.assign(EV, advanceFilters);

    // remove status from filters if all
    if(EV.status === 'all') EV.status = undefined;
    
    EV.page = invoicesCurrentPage;
    EV.resPerPage = invoicesPerPage;

    EV.orderBy = localStorage.getItem('prefInvoicesListingOrderBy');
    EV.orderDirection = localStorage.getItem('prefInvoicesListingOrderDirection');
    
    var res:any = await EV.getInvoices();

    // process adding key to the invocies list
    if(res.data) {
      var tempInvoices = [];
      
      for(var i = 0; i < res.data.length; i++) {
        tempInvoices.push({
          key: res.data[i].uuid,
          ...res.data[i]
        });
      }

      setInvoices(tempInvoices);
      setInvoicesCount(res?.count);
    }    

    setIsLoading(false);
    return null;
  }

  const updateStatus = async (uuid:React.Key, status:string) => {
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.status = status;
    EV.uuid = uuid;

    var res:any = await EV.updateInvoice();

    if(res && res.status === 'success') return true;
    else return false
  }

  const processAction = async (action:string) => {
    if(
      !selectedInvoices
      || selectedInvoices.length < 1
    ) {
      // show error
      setScreenModal({
        open: true,
        title: 'No Invoices Selected',
        children: <>
          <Typography.Paragraph>
            Please select the invocies you wish to update from the list below
          </Typography.Paragraph>
        </>
      });

      return;
    }
    
    if(isLoading) return;
    setIsLoading(true);

    var failedToUpdate:React.Key[] = [];

    var status = action.split(':')[1];

    if(!status) {
      setIsLoading(false);
      return;
    }

    // loop each invoice, update, then remove from the selected list
    for(var i = 0; i < selectedInvoices.length; i++) {
      var updateResult = await updateStatus(selectedInvoices[i], status);
      if(!updateResult) failedToUpdate.push(selectedInvoices[i]);
    }

    if(failedToUpdate.length > 0) {
      messageApi.warning(
        'Process completed with errors'
      )
    }

    else {
      messageApi.success(
        'Process completed successfully'
      );
    }

    setIsLoading(false);
    getInvoices();
    return;
  }

  const _renderFitlersToURL = () => {
    var linkQuery:string = '';
    
    if(searchTerm) {
      linkQuery += 'searchTerm=' + searchTerm + '&&';
    }

    if(
      advanceFilters
      && Object.keys(advanceFilters)?.length > 0
    ) {
      Object.entries(advanceFilters).map((property:[string, any], index:number) => {
        linkQuery += `${property[0]}=` 
          + property[1] 
          + '&&';
      })
    }

    return linkQuery;
  }

  return <BlankPage
    showSideMenu
    padded
    modal={screenModal}
    unauthorized={!authorized}
    customHeader={<Space size={'small'}>
      {(allowCreateNew) && <Button
        type={'primary'}
        icon={<PlusCircleFilled />}
        children={'Add New'}
        onClick={() => navigate(`/accounting/invoices/invoices/form/`)}
      />}
    </Space>}
  >
    
    {contextHolder}

    <Typography.Title children={'Invoices'} />

    <Row gutter={15}>
      <Col>
        <Segmented 
          value={advanceFilters?.status||'all'}
          style={{textTransform: 'capitalize'}}
          options={
            (isManagement) ? [
              {value: 'all', label: 'All'},
              {value: 'draft', label: 'Draft'},
              {value: 'issued', label: 'Issued'},
              {value: 'sent', label: 'Sent'},
              {value: 'unpaid', label: 'Unpaid'},
              {value: 'paid', label: 'Paid'},
              {value: 'void', label: 'Void'},
            ] : [
              {value: 'all', label: 'All'},
              {value: 'issued', label: 'Issued'},
              {value: 'sent', label: 'Sent'},
              {value: 'unpaid', label: 'Unpaid'},
              {value: 'paid', label: 'Paid'}
            ]
          }
          onChange={(value:any) => setAdvanceFilters({
            ...advanceFilters,
            status: value
          })}
        />
      </Col>
      <Col 
        flex={'auto'} 
        style={{
          display: 'flex', 
          justifyContent: 'flex-end', 
          alignItems: 'center'
        }}
      >
        <Space>
          {(allowEdit) && <Dropdown
            placement="topLeft" 
            disabled={(!selectedInvoices || selectedInvoices.length < 1)}
            arrow
            menu={{items: [
              { key: 'status:draft', label: <span>Update Status to: Draft</span>, onClick: () => processAction('status:draft') },
              { key: 'status:issued', label: <span>Update Status to: Issued</span>, onClick: () => processAction('status:issued') },
              { key: 'status:sent', label: <span>Update Status to: Sent</span>, onClick: () => processAction('status:sent') },
              { key: 'status:paid', label: <span>Update Status to: Paid</span>, onClick: () => processAction('status:paid') },
              { key: 'status:void', label: <span>Update Status to: Void</span>, onClick: () => processAction('status:void') },
            ]}}
          >
            <Button 
              type={'primary'}
              iconPosition='end'
              icon={(isLoading) ? <LoadingOutlined /> : <DownOutlined />}
              children={(selectedInvoices && selectedInvoices?.length > 0) 
                ? `Selected: ${selectedInvoices?.length} Invoice(s)`
                : `No invoices selected`
              }
            />
          </Dropdown>}
          <Button
            type={'default'}
            onClick={() => window.open(`/accounting/invoices/invoices/docs/export-list/?${_renderFitlersToURL()}`)}
            icon={<ExportOutlined />}
            children={'Export PDF'}
          />
          <Button
            type={'default'}
            onClick={() => setShowSideMenu(!showSideMenu)}
            icon={<EyeOutlined />}
            children={'Customize View'}
          />
        </Space>
      </Col>
    </Row>

    <TableColumnsCuztomization
      showDrawer={showSideMenu}
      onClose={() => setShowSideMenu(!showSideMenu)}
      onPrcessComplete={processPrefTableColumns}
      localStorageKey='prefInvoicesListingTableViewColumns'
      tableColumnsList={tableColumnsList}
    />

    <div style={{marginBottom: 20}} />

    <Row gutter={15}>
      <Col span={19}>
        <Input 
          placeholder={"Type to search"}
          suffix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Col>
      <Col span={5}>
        <Button 
          style={{width: '100%'}}
          type={"default"}
          onClick={() => setToggleAdvancedFilters(!toggleAdvancedFilters)}
        >
          Toggle Advanced Filters
        </Button>
      </Col>
    </Row>

    <div style={{marginBottom: 20}} />

    {(toggleAdvancedFilters) && <>
      <Typography.Title level={3}>
        Advance Search
      </Typography.Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={() => {
          getInvoices();
        }}
      >
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              name={'exporters_uuids'}
              label={'Exporter'}
            >
              <Select 
                showSearch
                allowClear
                mode='multiple'
                placeholder={'Select one'}
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  exporters_uuid: value,
                  exportersUuids: value
                })}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={exporters||[]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={6}>
            <Form.Item
              name={'issue_date_from'}
              label={'Issue Date (from)'}
            >
              <DatePicker
                style={{width: '100%'}}
                format="YYYY-MM-DD"
                value={advanceFilters.issueDateFromObj}
                onChange={(date, dateString) => {
                  setAdvanceFilters({
                    ...advanceFilters,
                    issueDateFrom: date.toISOString(),
                    issueDateFromObj: date
                  })
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={'issue_date_to'}
              label={'Issue Date (to)'}
            >
              <DatePicker
                style={{width: '100%'}}
                format="YYYY-MM-DD"
                value={advanceFilters.issueDateToObj}
                onChange={(date, dateString) => setAdvanceFilters({
                  ...advanceFilters,
                  issueDateTo: date.toISOString(),
                  issueDateToObj: date
                })}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={'due_date_from'}
              label={'Due Date (from)'}
            >
              <DatePicker
                style={{width: '100%'}}
                format="YYYY-MM-DD"
                value={advanceFilters.dueDateFromObj}
                onChange={(date, dateString) => setAdvanceFilters({
                  ...advanceFilters,
                  dueDateFrom: date.toISOString(),
                  dueDateFromObj: date
                })}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={'date_in_to'}
              label={'Date In (to)'}
            >
              <DatePicker
                style={{width: '100%'}}
                format="YYYY-MM-DD"
                value={advanceFilters.dueDateToObj}
                onChange={(date, dateString) => setAdvanceFilters({
                  ...advanceFilters,
                  dueDateTo: date.toISOString(),
                  dueDateToObj: date
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <Space>
                <Button 
                  type={"primary"} 
                  htmlType={"submit"}
                  loading={isLoading}
                >
                  Search
                </Button>
                <Button 
                  type={"default"}
                  onClick={() => {
                    setAdvanceFilters({});
                    form.resetFields();
                    setTimeout(() => getInvoices, 500);
                  }}
                >
                  Reset
                </Button>
                <Button 
                  type={"default"}
                  onClick={() => setToggleAdvancedFilters(!toggleAdvancedFilters)}
                >
                  Toggle Advanced Filters
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>}

    <div style={{marginBottom: 20}} />

    <Table
      {...(allowEdit && {
          rowSelection: {
            type: 'checkbox',
            onChange: (newSelectedRowKeys: React.Key[]) => {
              setSelectedInvoices(newSelectedRowKeys);
              return undefined;
            }
          }
        }
      )}
      size={userSettings('compact_view') ? 'small' : undefined}
      dataSource={invoices} 
      scroll={{ x: 1000 }}
      loading={isLoading}
      pagination={{
        total: invoicesCount||undefined,
        showSizeChanger: true,
        pageSizeOptions: [15, 50, 100, 200],
        defaultPageSize: 15,
        pageSize: invoicesPerPage,
        onChange: (page:number, pagesSize:number) => {
          localStorage.setItem('prefInvoicesListingResPerPage', pagesSize.toString())
          setInvoicesPerPage(pagesSize);
          setInvoicesCurrentPage(page);
        }
      }}
      columns={[
        (selectedColumns?.includes('uuid')) ? {
          key: 'uuid',
          title: 'UUID',
          fixed: 'left',
          dataIndex: 'uuid',
          width: 130,
          render: (_:any, record:any) => {
            return record.uuid.substr(record.uuid?.length - 10)
          }
        } : {},
        {
          key: 'status', 
          title: 'Status',
          fixed: 'left',
          width: 100,
          render: (_:any, record:any) => {
            var color = '';

            switch (record.status) {
              case 'issued':
                color = blue[5];
              break;
                
              case 'sent':
                color = gold[5];
              break;

              case 'paid':
                color = green[5];
              break;

              case 'void':
                color = red[5];
              break;
            }

            return <Tag
              color={color}
              children={record.status}
              style={{textTransform: 'capitalize'}} 
            />
          }
        },
        (selectedColumns?.includes('reference_number')) ? {
          key: 'reference_number', 
          title: 'Ref #', 
          dataIndex: 'reference_number', 
          width: 200,
          sorter: true,
          onHeaderCell: (column) => {
            return {
              onClick: () => resortTable(
                'Invoices', 
                'reference_number', 
                getInvoices
              )
            };
          }
        } : {},
        (selectedColumns?.includes('exporters_company_name')) ? {
          key: 'exporters_company_name', 
          title: 'Exporter', 
          width: 200,
          dataIndex: 'exporters_company_name',
          sorter: true,
          onHeaderCell: (column) => {
            return {
              onClick: () => resortTable(
                'Invoices', 
                'exporters_company_name', 
                getInvoices
              )
            };
          }
        } : {},
        (selectedColumns?.includes('issue_date')) ? {
          key: 'issue_date', 
          title: 'Issue Date', 
          dataIndex: 'issue_date',
          width: 170,
          sorter: true,
          onHeaderCell: (column) => {
            return {
              onClick: () => resortTable(
                'Invoices', 
                'issue_date', 
                getInvoices
              )
            };
          },
          render: (_:any, record:any) => {
            if(record.issue_date) {
              return momentTz(record.issue_date)
              .tz(moment.tz.guess())
              .format('MM/DD/YYYY');
            }

            return '-';
          }
        } : {},
        (selectedColumns?.includes('due_date')) ? {
          key: 'due_date', 
          title: 'Due Date', 
          dataIndex: 'due_date',
          width: 170,
          sorter: true,
          onHeaderCell: (column) => {
            return {
              onClick: () => resortTable(
                'Invoices', 
                'due_date', 
                getInvoices
              )
            };
          },
          render: (_:any, record:any) => {
            if(record.due_date) {
              return momentTz(record.due_date)
              .tz(moment.tz.guess())
              .format('MM/DD/YYYY');
            }

            return '-';
          }
        } : {},
        (selectedColumns?.includes('sub_total')) ? {
          key: 'sub_total', 
          title: 'Sub Total', 
          dataIndex: 'sub_total', 
          align: 'right',
          width: 150,
          render: (_:any, record:any) => {
            return record.sub_total?.toFixed(2);
          }
        } : {},
        (selectedColumns?.includes('tax_total')) ? {
          key: 'tax_total', 
          title: 'Tax', 
          dataIndex: 'tax_total', 
          align: 'right',
          width: 100,
          render: (_:any, record:any) => {
            return record.tax_total?.toFixed(2);
          }
        } : {},
        (selectedColumns?.includes('grand_total')) ? {
          key: 'grand_total', 
          title: 'Total', 
          dataIndex: 'grand_total', 
          align: 'right',
          width: 150,
          sorter: true,
          onHeaderCell: (column) => {
            return {
              onClick: () => resortTable(
                'Invoices', 
                'grand_total', 
                getInvoices
              )
            };
          },
          render: (_:any, record:any) => {
            return <Typography.Text strong>
              {record.grand_total?.toFixed(2)}
            </Typography.Text>
          }
        } : {},
        (selectedColumns?.includes('balance')) ? {
          key: 'balance', 
          title: 'Unpaid Balance', 
          align: 'right',
          width: 150,
          render: (_:any, record:any) => {
            var value = 0;

            if(record.total_paid) {
              value = record.grand_total - record.total_paid
            }

            else value = record.grand_total;

            return value.toFixed(2);
          }
        } : {},
        {
          key: 'actions',
          title: 'Actions',
          fixed: 'right',
          width: 130,
          render: (_:any, record:any) => <Space size={'small'}>
            <Button
              type='link'
              icon={<AuditOutlined />}
              onClick={() => window.open(`/accounting/invoices/invoices/view/industrial/${record.uuid}`, '_blank')}
            />
            <Button
              type='link'
              icon={<ExpandAltOutlined />}
              onClick={() => window.open(`/accounting/invoices/invoices/view/modern/${record.uuid}`, '_blank')}
            />
            {(allowEdit) && <Button
              type='link'
              icon={<FormOutlined />}
              title={'Manage'}
              onClick={() => navigate(`/accounting/invoices/invoices/form/${record.uuid}`)}
            />}
          </Space>
        }
      ]}
    />
  </BlankPage>
}