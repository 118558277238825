/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import React, { useState, useEffect } from 'react';

import {Alert, Button, ConfigProvider, message, theme} from 'antd';

import { 
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import EVAPI from './lib/ev_lib/main';
import { convertSystemSettingsArrayDatasetToObject } from './lib/ev_lib/data_processors';
import { URLQuery } from './util/url_queries';

// components / screens
import Login from './auth/login';
import Home from './home/home';
import BlankPage from './framework/blank_page';

/** Parties */
import AddressBook from './address_book/general/address_book';
import ContactsForm from './address_book/general/contact_form';
import ExportersForm from './address_book/exporters/form';
import ExportersListing from './address_book/exporters/listing';
import IntermediateConsigneesForm from './address_book/intermediate_consignees/form';
import IntermediateConsigneesListing from './address_book/intermediate_consignees/listing';
import TruckingCompaniesListing from './address_book/trucking_companies/listing';
import TruckingCompaniesForm from './address_book/trucking_companies/form';
import SteamShipLinesListing from './address_book/steam_ship_lines/listing';
import SteamShipLinesForm from './address_book/steam_ship_lines/form';

import SettingsList from './system/settings/listing';
import SettingsProfile from './system/settings/users/view';
import SettingsGeneral from './system/settings/general';
import SettingsWarehousesListing from './system/settings/warehouses/warehouses_listing';
import SettingsWarehousesForm from './system/settings/warehouses/warehouses_form';
import SettingsEquipmentsTypesListing from './system/settings/equipments_types/equipments_types_listing';
import SettingsEquipmentsTypesForm from './system/settings/equipments_types/equipments_types_form';
import SettingsModulesRecordsStatusesList from './system/settings/modules_records_statuses/modules_records_statuses';
import SettingsModulesRecordsStatusesForm from './system/settings/modules_records_statuses/modules_records_statuses_form';
import SettingsUsersListing from './system/settings/users/listing';
import SettingsUsersForm from './system/settings/users/form';
import SettingsUsersGroupsListing from './system/settings/users_groups/listing';
import SettingsUsersView from './system/settings/users/view';
import SettingsUsersGroupsForm from './system/settings/users_groups/form';
import SettingsDropOffDisabledDates from './system/settings/drop_off/disabled_dates';
import SettingsDropOffTimeSlots from './system/settings/drop_off/time_slots';

import DropOffsAppointmentsListing from './drop_off/listing';
import DropOffsAppointmentsForm from './drop_off/form';
import ExportDropOffAppointmentsListing from './drop_off/docs/export_listing';

import CargoesListing from './inventory/cargoes/listing';
import CargoesForm from './inventory/cargoes/form';
import ContainersListing from './containers/containers/listing';
import ContainersForm from './containers/containers/form';
import PackingOrdersListing from './containers//packing_orders/listing';
import PackingOrdersForm from './containers/packing_orders/form';
import CargoesView from './inventory/cargoes/view';
import ContainersView from './containers/containers/view';
import PackingOrdersView from './containers/packing_orders/view';
import PackingPlanForm from './containers/packing_plans/form';
import PackingPlansListing from './containers/packing_plans/list';
import ExportCargoesListing from './inventory/cargoes/docs/export_listing';
import CargoesViewGuest from './inventory/cargoes/view.guest';

import AboutSystem from './system/about';

import InvoicesListing from './accounting/invoices/invoices/listing';
import InvoicesFormNavigation from './accounting/invoices/invoices/form';
import SettingsIncomeDepositAccountsList from './system/settings/accounting/income_deposit_accounts/income_deposit_accounts';
import SettingsIncomeDepositAccountsForm from './system/settings/accounting/income_deposit_accounts/income_deposit_accounts_form';
import InvoiceModernView from './accounting/invoices/invoices/docs/modern_invoice';
import InvoiceIndustrialView from './accounting/invoices/invoices/docs/industrial_invoice';

import SettingsAccountingGeneral from './system/settings/accounting/general/general';
import ExportInvoicesListing from './accounting/invoices/invoices/docs/export_listing';
import SettingsDocumentsBin from './system/settings/storage/bin';
import { userSettings } from './lib/user_settings';
import MFA from './auth/mfa';


// check if the user is logged in
var auth:string|null = localStorage.getItem('auth_token');

export default function App(props:any) {
  const isDarkMode = localStorage.getItem('isDarkMode') || false;

  const [isLoadingGlobalDispatchSettings, setIsLoadingGlobalDispatchSettings] = useState<boolean>(false);
  const [globalDispatchSettingsFail, setGlobalDispatchSettingsFail] = useState<boolean>(false);
  const [isLoadingCompanySettings, setIsLoadingCompanySettings] = useState<boolean>(false);
  const [companySettingsFail, setCompanySettingsFail] = useState<boolean>(false);
  const [modeReset, setModeReset] = useState<boolean>(false);
  
  useEffect(() => {
    getDispatchGlobalSettings();
    getCompanySettings();
  }, []);

  // download the system settings
  const getDispatchGlobalSettings = async () => {
    if(isLoadingGlobalDispatchSettings) return;
    setIsLoadingGlobalDispatchSettings(true);
    setGlobalDispatchSettingsFail(false);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');

    var res:any = await EV.getDispatchGlobalSettings();
    
    if(
      (
        !res
        || res.status !== 'success'
      )
      && !process.env.REACT_APP_FORCED_CORE_URI
    ) {
      if(process)
      setGlobalDispatchSettingsFail(true);
    }

    else {
      // store the configurations
      var data = res.data;
      if(data) data = JSON.stringify(data);
      localStorage.setItem('dispatchGlobalSettings', data); 
    }

    setIsLoadingGlobalDispatchSettings(false);
    return;
  }

  const getCompanySettings = async () => {
    if(isLoadingCompanySettings) return null;
    setIsLoadingCompanySettings(true);
    setCompanySettingsFail(false);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = auth;
    
    var res:any = await EV.getSettings();

    if(!res || res.code !== 200) {
      setCompanySettingsFail(true)
    }

    else if(
      res.code === 200
      && res.data
    ) {
      var data = convertSystemSettingsArrayDatasetToObject(res.data);
      localStorage.setItem('company_settings', JSON.stringify(data));
    }

    setIsLoadingCompanySettings(false);
    return;
  }

  return <ConfigProvider
    theme={{
      algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
      token: {
        wireframe: true,
        colorPrimary: '#2185d0',
        colorLink: '#2185d0'
      },
    }}
  >
    <div 
      className={userSettings('compact_view') ? 'compact-view' : undefined}
    >
      {(globalDispatchSettingsFail) && <>
        <Alert
          message={'Some functions may not run properly'}
          description={'Portal failed to download the global '
            + 'dispatch settings. As a result, some function '
            + 'may not work. We recommend to try again. '
            + 'E:10002'}
          type={'error'}
          action={
            <Button 
              size="small" 
              type={'text'}
              onClick={getDispatchGlobalSettings}
            >
              Try Again
            </Button>
          }
          banner
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 1000000
          }}
        />
      </> }
      {(companySettingsFail) && <>
        <Alert
          message={'Some functions may not run properly'}
          description={'Portal failed to download the global '
            + 'dispatch settings. As a result, some function '
            + 'may not work. We recommend to try again. '
            + 'E:10004'}
          type={'error'}
          action={
            <Button 
              size="small" 
              type={'text'}
              onClick={getCompanySettings}
            >
              Try Again
            </Button>
          }
          banner
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 1000000
          }}
        />
      </> }
      {(globalDispatchSettingsFail || companySettingsFail) && <div style={{height: '50px'}} />}
      <React.Suspense fallback="Loading...">
      <BrowserRouter>
        <Routes>
          {/** Accounting routs */}
          <Route path="/accounting/invoices/invoices/view/industrial/:uuid" element={<InvoiceIndustrialView auth={auth} />}></Route>
          <Route path="/accounting/invoices/invoices/view/modern/:uuid" element={<InvoiceModernView auth={auth} />}></Route>
          <Route path="/accounting/invoices/invoices/form/:uuid" element={auth ? <InvoicesFormNavigation /> : <Login />}></Route>
          <Route path="/accounting/invoices/invoices/form" element={auth ? <InvoicesFormNavigation /> : <Login />}></Route>
          <Route path="/accounting/invoices/invoices/docs/export-list" element={auth ? <ExportInvoicesListing /> : <Login />}></Route>
          <Route path="/accounting/invoices/invoices" element={auth ? <InvoicesListing /> : <Login />}></Route>

          {/** parties screens **/}
          <Route path="/exporters/form/:uuid" element={auth ? <ExportersForm /> : <Login />}></Route>
          <Route path="/exporters/form" element={auth ? <ExportersForm /> : <Login />}></Route>
          <Route path="/exporters" element={auth ? <ExportersListing /> : <Login />}></Route>
          
          <Route path="/intermediate-consignees/form/:uuid" element={auth ? <IntermediateConsigneesForm /> : <Login />}></Route>
          <Route path="/intermediate-consignees/form" element={auth ? <IntermediateConsigneesForm /> : <Login />}></Route>
          <Route path="/intermediate-consignees" element={auth ? <IntermediateConsigneesListing /> : <Login />}></Route>

          <Route path="/trucking-companies/form/:uuid" element={auth ? <TruckingCompaniesForm /> : <Login />}></Route>
          <Route path="/trucking-companies/form" element={auth ? <TruckingCompaniesForm /> : <Login />}></Route>
          <Route path="/trucking-companies" element={auth ? <TruckingCompaniesListing /> : <Login />}></Route>

          <Route path="/steam-ship-lines/form/:uuid" element={auth ? <SteamShipLinesForm /> : <Login />}></Route>
          <Route path="/steam-ship-lines/form" element={auth ? <SteamShipLinesForm /> : <Login />}></Route>
          <Route path="/steam-ship-lines" element={auth ? <SteamShipLinesListing /> : <Login />}></Route>

          <Route path="/address-book/form/:uuid" element={auth ? <ContactsForm /> : <Login />}></Route>
          <Route path="/address-book/form" element={auth ? <ContactsForm /> : <Login />}></Route>
          <Route path="/address-book" element={auth ? <AddressBook /> : <Login />}></Route>

          {/** drop off **/}
          <Route path="/drop-off/appointments/form/:uuid" element={auth ? <DropOffsAppointmentsForm /> : <Login />}></Route>
          <Route path="/drop-off/appointments/form" element={auth ? <DropOffsAppointmentsForm /> : <Login />}></Route>
          <Route path="/drop-off/appointments" element={auth ? <DropOffsAppointmentsListing /> : <Login />}></Route>
          <Route path="/drop-off/appointments/doc/export-list" element={auth ? <ExportDropOffAppointmentsListing /> : <Login />}></Route>

          {/** containers screens **/}
          <Route path="/containers/containers/view/:uuid" element={auth ? <ContainersView /> : <Login />}></Route>
          <Route path="/containers/containers/form/:uuid" element={auth ? <ContainersForm /> : <Login />}></Route>
          <Route path="/containers/containers/form" element={auth ? <ContainersForm /> : <Login />}></Route>
          <Route path="/containers/containers" element={auth ? <ContainersListing /> : <Login />}></Route>

          {/** packing orders screens **/}
          <Route path="/containers/packing-orders/view/:uuid" element={auth ? <PackingOrdersView /> : <Login />}></Route>
          <Route path="/containers/packing-orders/form/:uuid" element={auth ? <PackingOrdersForm /> : <Login />}></Route>
          <Route path="/containers/packing-orders/form" element={auth ? <PackingOrdersForm /> : <Login />}></Route>
          <Route path="/containers/packing-orders" element={auth ? <PackingOrdersListing /> : <Login />}></Route>

          {/** cargoes screens **/}
          <Route path="/inventory/cargoes/view/guest/:uuid" element={<CargoesViewGuest  />}></Route>
          <Route path="/inventory/cargoes/view/:uuid" element={auth ? <CargoesView  /> : <Login />}></Route>
          <Route path="/inventory/cargoes/form/:uuid" element={auth ? <CargoesForm  /> : <Login />}></Route>
          <Route path="/inventory/cargoes/form" element={auth ? <CargoesForm /> : <Login />}></Route>
          <Route path="/inventory/cargoes/doc/export-list" element={auth ? <ExportCargoesListing /> : <Login />}></Route>
          <Route path="/inventory/cargoes" element={auth ? <CargoesListing /> : <Login />}></Route>

          {/** packing plans screens **/}
          <Route path="/containers/packing-plans/form/:uuid" element={auth ? <PackingPlanForm  /> : <Login />}></Route>
          <Route path="/containers/packing-plans/form" element={auth ? <PackingPlanForm /> : <Login />}></Route>
          <Route path="/containers/packing-plans" element={auth ? <PackingPlansListing /> : <Login />}></Route>

          {/** settings screens **/}
          <Route path="/system/settings/general" element={auth ? <SettingsGeneral /> : <Login />}></Route>

          <Route path="/system/settings/drop-off/disabled-dates" element={auth ? <SettingsDropOffDisabledDates /> : <Login />}></Route>
          <Route path="/system/settings/drop-off/time-slots" element={auth ? <SettingsDropOffTimeSlots /> : <Login />}></Route>

          <Route path="/system/settings/equipments-types/form/:uuid" element={auth ? <SettingsEquipmentsTypesForm /> : <Login />}></Route>
          <Route path="/system/settings/equipments-types/form" element={auth ? <SettingsEquipmentsTypesForm /> : <Login />}></Route>
          <Route path="/system/settings/equipments-types" element={auth ? <SettingsEquipmentsTypesListing /> : <Login />}></Route>
          
          <Route path="/system/settings/warehouses/form/:uuid" element={auth ? <SettingsWarehousesForm /> : <Login />}></Route>
          <Route path="/system/settings/warehouses/form" element={auth ? <SettingsWarehousesForm /> : <Login />}></Route>
          <Route path="/system/settings/warehouses" element={auth ? <SettingsWarehousesListing /> : <Login />}></Route>
          
          <Route path="/system/settings/storage/bin" element={auth ? <SettingsDocumentsBin /> : <Login />}></Route>

          <Route path="/system/settings/modules-records-statuses/form/:uuid" element={auth ? <SettingsModulesRecordsStatusesForm /> : <Login />}></Route>
          <Route path="/system/settings/modules-records-statuses/form" element={auth ? <SettingsModulesRecordsStatusesForm /> : <Login />}></Route>
          <Route path="/system/settings/modules-records-statuses" element={auth ? <SettingsModulesRecordsStatusesList /> : <Login />}></Route>
          
          <Route path="/system/settings/users/view/:uuid" element={auth ? <SettingsUsersView /> : <Login />}></Route>
          <Route path="/system/settings/users/form/:uuid" element={auth ? <SettingsUsersForm /> : <Login />}></Route>
          <Route path="/system/settings/users/form" element={auth ? <SettingsUsersForm /> : <Login />}></Route>
          <Route path="/system/settings/users" element={auth ? <SettingsUsersListing /> : <Login />}></Route>
          
          <Route path="/system/settings/users-groups/form/:uuid" element={auth ? <SettingsUsersGroupsForm /> : <Login />}></Route>
          <Route path="/system/settings/users-groups/form" element={auth ? <SettingsUsersGroupsForm /> : <Login />}></Route>
          <Route path="/system/settings/users-groups" element={auth ? <SettingsUsersGroupsListing /> : <Login />}></Route>

          <Route path="/system/settings/accounting/income-deposit-accounts/form/:uuid" element={auth ? <SettingsIncomeDepositAccountsForm /> : <Login />}></Route>
          <Route path="/system/settings/accounting/income-deposit-accounts/form" element={auth ? <SettingsIncomeDepositAccountsForm /> : <Login />}></Route>
          <Route path="/system/settings/accounting/income-deposit-accounts" element={auth ? <SettingsIncomeDepositAccountsList /> : <Login />}></Route>
          <Route path="/system/settings/accounting/general" element={auth ? <SettingsAccountingGeneral /> : <Login />}></Route>
          
          <Route path="/system/settings" element={auth ? <SettingsList /> : <Login />}></Route>
          <Route path="/system/about" element={auth ? <AboutSystem /> : <Login />}></Route>

          {/** General screens **/}
          <Route path="/auth/mfa" element={<MFA />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/" element={auth ? <Home /> : <Login />}></Route>

          {/** 404 */}
          <Route path="*" element={auth ? <BlankPage showSideMenu padded error notFound /> : <Login />}></Route>
        </Routes>
      </BrowserRouter>
      </React.Suspense>
    </div>

    <Button 
      id="color-mode-switch"
      style={{
        position: 'absolute',
        bottom: 20,
        right: 15,
        zIndex: 100000000
      }}
      onClick={() => {
        if(isDarkMode) localStorage.removeItem('isDarkMode') 
        else localStorage.setItem('isDarkMode', 'true')

        setModeReset(!modeReset)
      }}
      shape='circle'
      type='link'
    >
      <img src={require('./framework/assets/dark-mode.png')} width={40} />
    </Button>
  </ConfigProvider>
}