/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { DeleteFilled, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Menu, message, Pagination, Row, Segmented, Select, Space, Switch, Table, Typography } from "antd";

import BlankPage, { LayoutModalProps } from "../../../framework/blank_page";
import EVAPI from '../../../lib/ev_lib/main';
import { useNavigate, useParams } from 'react-router-dom';
import { filterGeoArray } from '../../../lib/cities';
import { setClearFormFields } from '../../../util/clear_empty';
import { URLQuery } from '../../../util/url_queries';

export default function SettingsWarehousesForm(props:any) {
  interface warehouse {
    uuid: string|null,
    name: string|null,
    country_code?: string,
    phone?: string|number,
    email?: string,
    address_1?: string,
    address_2?: string,
    city?: string,
    state?: string,
    country?: string,
    zip_code?: string|number,
    is_active?: 'y'|'n'|null,
    clear?:string[]|undefined
  }

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [errors, setErrors] = useState<any[]|null>(null);

  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);
  const [cities, setCities] = useState<{value:string,label:string}[]|undefined>(undefined);

  const [isLoadingStates, setIsLoadingStates] = useState<boolean>(false);
  const [states, setStates] = useState<{value:string,label:string}[]|undefined>(undefined);

  const [isLoadingCountries, setIsLoadingCountries] = useState<boolean>(false);
  const [countries, setCountries] = useState<{value:string,label:string}[]|undefined>(undefined);

  const [warehouse, setWarehouse] = useState<warehouse>({
    uuid: uuid||null,
    name: null,
    country_code: '+1'
  });

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getCities();
    getStates();
    getCountries();

    if(uuid) getWarehouse();

    setIsReady(true);
  }, []);

  const getCities = async () => {
    if(isLoadingCities) return;
    setIsLoadingCities(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'cities';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.id, 
          label: item.name, 
          state_name: item.state_name,
          country_name: item.country_name
        })
      })

      setCities(results);
    }

    setIsLoadingCities(false);
    return null;
  }

  const getStates = async () => {
    if(isLoadingStates) return;
    setIsLoadingStates(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'states';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.id, 
          label: item.name,
          country_name: item.country_name
        })
      })

      setStates(results);
    }

    setIsLoadingStates(false);
    return null;
  }

  const getCountries = async () => {
    if(isLoadingCountries) return;
    setIsLoadingCountries(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'countries';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.id, label: `${item.name}`
        })
      })

      setCountries(results);
    }

    setIsLoadingCountries(false);
    return null;
  }

  const getWarehouse = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getWarehouse();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if(res.data) {
      setWarehouse(res.data);
      form.setFieldsValue(res.data)
    }

    setIsLoading(false);
    return null;
  }

  const processWarehouse = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    // handle cleared fields
    if(warehouse.uuid) {
      // check clearables
      warehouse.clear = setClearFormFields(warehouse)||undefined;
    }

    Object.assign(EV, warehouse);

    var res:any = null;

    if(warehouse.uuid) res = await EV.updateWarehouse();
    else res = await EV.createWarehouse();

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if (res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      if(!warehouse.uuid) {
        setWarehouse({
          ...warehouse,
          uuid: res.data.uuid
        });

        navigate('/system/settings/warehouses/form/' + res.data.uuid, { replace: true });
      }
    }

    setIsLoading(false);
    return null;
  }

  const deleteWarehouse = async () => {
    if(isDeleting) return;
    setIsDeleting(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = warehouse?.uuid;
    
    var res:any = await EV.deleteWarehouse();
    // var res:any = false;
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }

  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(warehouse.uuid) && <>
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deleteWarehouse();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
  >
    {contextHolder}

    <Typography.Title 
      level={1}
      children={(warehouse?.uuid) ? 'Manage Warehouse' : 'New Warehouse'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={warehouse?.uuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        uuid: uuid||warehouse.uuid,
        name: warehouse.name,
        country_code: warehouse.country_code,
        phone: warehouse.phone,
        email: warehouse.email,
        address_1: warehouse.address_1,
        address_2: warehouse.address_2,
        city: warehouse.city,
        state: warehouse.state,
        country: warehouse.country,
        is_active: warehouse.is_active === 'y' ? true : false
      }}
      onFinish={processWarehouse}
    >
      <Form.Item
        label={'Name'} 
        name={'name'}
        rules={[{ required: true, message: 'Field required'}]}
      >
        <Input 
          placeholder={'Type here'}
          defaultValue={'123'}
          onChange={(e) => setWarehouse({
            ...warehouse,
            name: e.target.value
          })}
        />
      </Form.Item>

      <Row gutter={15}>
        <Col span={12}>
          <Form.Item
            label={'Phone'} 
            name={'phone'}
          >
            <Input 
              addonBefore={<Select 
                showSearch
                style={{width: 100}} 
                value={warehouse.country_code||'+1'}
                onChange={(value) => setWarehouse({
                  ...warehouse, 
                  country_code: value
                })}
              >
                <Select.Option value="+1">+1</Select.Option>
                <Select.Option value="+7">+7</Select.Option>
                <Select.Option value="+20">+20</Select.Option>
                <Select.Option value="+27">+27</Select.Option>
                <Select.Option value="+30">+30</Select.Option>
                <Select.Option value="+31">+31</Select.Option>
                <Select.Option value="+32">+32</Select.Option>
                <Select.Option value="+33">+33</Select.Option>
                <Select.Option value="+34">+34</Select.Option>
                <Select.Option value="+36">+36</Select.Option>
                <Select.Option value="+39">+39</Select.Option>
                <Select.Option value="+40">+40</Select.Option>
                <Select.Option value="+41">+41</Select.Option>
                <Select.Option value="+43">+43</Select.Option>
                <Select.Option value="+44">+44</Select.Option>
                <Select.Option value="+45">+45</Select.Option>
                <Select.Option value="+46">+46</Select.Option>
                <Select.Option value="+47">+47</Select.Option>
                <Select.Option value="+48">+48</Select.Option>
                <Select.Option value="+49">+49</Select.Option>
                <Select.Option value="+51">+51</Select.Option>
                <Select.Option value="+52">+52</Select.Option>
                <Select.Option value="+53">+53</Select.Option>
                <Select.Option value="+54">+54</Select.Option>
                <Select.Option value="+55">+55</Select.Option>
                <Select.Option value="+56">+56</Select.Option>
                <Select.Option value="+57">+57</Select.Option>
                <Select.Option value="+58">+58</Select.Option>
                <Select.Option value="+60">+60</Select.Option>
                <Select.Option value="+61">+61</Select.Option>
                <Select.Option value="+62">+62</Select.Option>
                <Select.Option value="+63">+63</Select.Option>
                <Select.Option value="+64">+64</Select.Option>
                <Select.Option value="+65">+65</Select.Option>
                <Select.Option value="+66">+66</Select.Option>
                <Select.Option value="+81">+81</Select.Option>
                <Select.Option value="+82">+82</Select.Option>
                <Select.Option value="+84">+84</Select.Option>
                <Select.Option value="+86">+86</Select.Option>
                <Select.Option value="+90">+90</Select.Option>
                <Select.Option value="+91">+91</Select.Option>
                <Select.Option value="+92">+92</Select.Option>
                <Select.Option value="+93">+93</Select.Option>
                <Select.Option value="+94">+94</Select.Option>
                <Select.Option value="+95">+95</Select.Option>
                <Select.Option value="+98">+98</Select.Option>
                <Select.Option value="+211">+211</Select.Option>
                <Select.Option value="+212">+212</Select.Option>
                <Select.Option value="+213">+213</Select.Option>
                <Select.Option value="+216">+216</Select.Option>
                <Select.Option value="+218">+218</Select.Option>
                <Select.Option value="+220">+220</Select.Option>
                <Select.Option value="+221">+221</Select.Option>
                <Select.Option value="+222">+222</Select.Option>
                <Select.Option value="+223">+223</Select.Option>
                <Select.Option value="+224">+224</Select.Option>
                <Select.Option value="+225">+225</Select.Option>
                <Select.Option value="+226">+226</Select.Option>
                <Select.Option value="+227">+227</Select.Option>
                <Select.Option value="+228">+228</Select.Option>
                <Select.Option value="+229">+229</Select.Option>
                <Select.Option value="+230">+230</Select.Option>
                <Select.Option value="+231">+231</Select.Option>
                <Select.Option value="+232">+232</Select.Option>
                <Select.Option value="+233">+233</Select.Option>
                <Select.Option value="+234">+234</Select.Option>
                <Select.Option value="+235">+235</Select.Option>
                <Select.Option value="+236">+236</Select.Option>
                <Select.Option value="+237">+237</Select.Option>
                <Select.Option value="+238">+238</Select.Option>
                <Select.Option value="+239">+239</Select.Option>
                <Select.Option value="+240">+240</Select.Option>
                <Select.Option value="+241">+241</Select.Option>
                <Select.Option value="+242">+242</Select.Option>
                <Select.Option value="+243">+243</Select.Option>
                <Select.Option value="+244">+244</Select.Option>
                <Select.Option value="+245">+245</Select.Option>
                <Select.Option value="+246">+246</Select.Option>
                <Select.Option value="+248">+248</Select.Option>
                <Select.Option value="+249">+249</Select.Option>
                <Select.Option value="+250">+250</Select.Option>
                <Select.Option value="+251">+251</Select.Option>
                <Select.Option value="+252">+252</Select.Option>
                <Select.Option value="+253">+253</Select.Option>
                <Select.Option value="+254">+254</Select.Option>
                <Select.Option value="+255">+255</Select.Option>
                <Select.Option value="+256">+256</Select.Option>
                <Select.Option value="+257">+257</Select.Option>
                <Select.Option value="+258">+258</Select.Option>
                <Select.Option value="+260">+260</Select.Option>
                <Select.Option value="+261">+261</Select.Option>
                <Select.Option value="+262">+262</Select.Option>
                <Select.Option value="+263">+263</Select.Option>
                <Select.Option value="+264">+264</Select.Option>
                <Select.Option value="+265">+265</Select.Option>
                <Select.Option value="+266">+266</Select.Option>
                <Select.Option value="+267">+267</Select.Option>
                <Select.Option value="+268">+268</Select.Option>
                <Select.Option value="+269">+269</Select.Option>
                <Select.Option value="+290">+290</Select.Option>
                <Select.Option value="+291">+291</Select.Option>
                <Select.Option value="+297">+297</Select.Option>
                <Select.Option value="+298">+298</Select.Option>
                <Select.Option value="+299">+299</Select.Option>
                <Select.Option value="+350">+350</Select.Option>
                <Select.Option value="+351">+351</Select.Option>
                <Select.Option value="+352">+352</Select.Option>
                <Select.Option value="+353">+353</Select.Option>
                <Select.Option value="+354">+354</Select.Option>
                <Select.Option value="+355">+355</Select.Option>
                <Select.Option value="+356">+356</Select.Option>
                <Select.Option value="+357">+357</Select.Option>
                <Select.Option value="+358">+358</Select.Option>
                <Select.Option value="+359">+359</Select.Option>
                <Select.Option value="+370">+370</Select.Option>
                <Select.Option value="+371">+371</Select.Option>
                <Select.Option value="+372">+372</Select.Option>
                <Select.Option value="+373">+373</Select.Option>
                <Select.Option value="+374">+374</Select.Option>
                <Select.Option value="+375">+375</Select.Option>
                <Select.Option value="+376">+376</Select.Option>
                <Select.Option value="+377">+377</Select.Option>
                <Select.Option value="+378">+378</Select.Option>
                <Select.Option value="+380">+380</Select.Option>
                <Select.Option value="+381">+381</Select.Option>
                <Select.Option value="+382">+382</Select.Option>
                <Select.Option value="+383">+383</Select.Option>
                <Select.Option value="+385">+385</Select.Option>
                <Select.Option value="+386">+386</Select.Option>
                <Select.Option value="+387">+387</Select.Option>
                <Select.Option value="+389">+389</Select.Option>
                <Select.Option value="+420">+420</Select.Option>
                <Select.Option value="+421">+421</Select.Option>
                <Select.Option value="+423">+423</Select.Option>
                <Select.Option value="+500">+500</Select.Option>
                <Select.Option value="+501">+501</Select.Option>
                <Select.Option value="+502">+502</Select.Option>
                <Select.Option value="+503">+503</Select.Option>
                <Select.Option value="+504">+504</Select.Option>
                <Select.Option value="+505">+505</Select.Option>
                <Select.Option value="+506">+506</Select.Option>
                <Select.Option value="+507">+507</Select.Option>
                <Select.Option value="+508">+508</Select.Option>
                <Select.Option value="+509">+509</Select.Option>
                <Select.Option value="+590">+590</Select.Option>
                <Select.Option value="+591">+591</Select.Option>
                <Select.Option value="+592">+592</Select.Option>
                <Select.Option value="+593">+593</Select.Option>
                <Select.Option value="+594">+594</Select.Option>
                <Select.Option value="+595">+595</Select.Option>
                <Select.Option value="+596">+596</Select.Option>
                <Select.Option value="+597">+597</Select.Option>
                <Select.Option value="+598">+598</Select.Option>
                <Select.Option value="+599">+599</Select.Option>
                <Select.Option value="+670">+670</Select.Option>
                <Select.Option value="+672">+672</Select.Option>
                <Select.Option value="+673">+673</Select.Option>
                <Select.Option value="+674">+674</Select.Option>
                <Select.Option value="+675">+675</Select.Option>
                <Select.Option value="+676">+676</Select.Option>
                <Select.Option value="+677">+677</Select.Option>
                <Select.Option value="+678">+678</Select.Option>
                <Select.Option value="+679">+679</Select.Option>
                <Select.Option value="+680">+680</Select.Option>
                <Select.Option value="+681">+681</Select.Option>
                <Select.Option value="+682">+682</Select.Option>
                <Select.Option value="+683">+683</Select.Option>
                <Select.Option value="+685">+685</Select.Option>
                <Select.Option value="+686">+686</Select.Option>
                <Select.Option value="+687">+687</Select.Option>
                <Select.Option value="+688">+688</Select.Option>
                <Select.Option value="+689">+689</Select.Option>
                <Select.Option value="+690">+690</Select.Option>
                <Select.Option value="+691">+691</Select.Option>
                <Select.Option value="+692">+692</Select.Option>
                <Select.Option value="+850">+850</Select.Option>
                <Select.Option value="+852">+852</Select.Option>
                <Select.Option value="+853">+853</Select.Option>
                <Select.Option value="+855">+855</Select.Option>
                <Select.Option value="+856">+856</Select.Option>
                <Select.Option value="+880">+880</Select.Option>
                <Select.Option value="+886">+886</Select.Option>
                <Select.Option value="+960">+960</Select.Option>
                <Select.Option value="+961">+961</Select.Option>
                <Select.Option value="+962">+962</Select.Option>
                <Select.Option value="+963">+963</Select.Option>
                <Select.Option value="+964">+964</Select.Option>
                <Select.Option value="+965">+965</Select.Option>
                <Select.Option value="+966">+966</Select.Option>
                <Select.Option value="+967">+967</Select.Option>
                <Select.Option value="+968">+968</Select.Option>
                <Select.Option value="+970">+970</Select.Option>
                <Select.Option value="+971">+971</Select.Option>
                <Select.Option value="+972">+972</Select.Option>
                <Select.Option value="+973">+973</Select.Option>
                <Select.Option value="+974">+974</Select.Option>
                <Select.Option value="+975">+975</Select.Option>
                <Select.Option value="+976">+976</Select.Option>
                <Select.Option value="+977">+977</Select.Option>
                <Select.Option value="+992">+992</Select.Option>
                <Select.Option value="+993">+993</Select.Option>
                <Select.Option value="+994">+994</Select.Option>
                <Select.Option value="+995">+995</Select.Option>
                <Select.Option value="+996">+996</Select.Option>
                <Select.Option value="+998">+998</Select.Option>
              </Select>}
              placeholder={'Type here'}
              onChange={(e) => setWarehouse({
                ...warehouse,
                phone: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Email'} 
            name={'email'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setWarehouse({
                ...warehouse,
                email: e.target.value
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={15}>
        <Col span={16}>
          <Form.Item
            label={'Street Address'} 
            name={'address_1'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setWarehouse({
                ...warehouse,
                address_1: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'Unit #'} 
            name={'address_2'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setWarehouse({
                ...warehouse,
                address_2: e.target.value
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={'Country'} 
        name={'country'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          onChange={(value, option:any) => setWarehouse({
            ...warehouse,
            country: option?.label||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={countries}
        />
      </Form.Item>
      
      <Form.Item
        label={'State'} 
        name={'state'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          onChange={(value, option:any) => setWarehouse({
            ...warehouse,
            state: option?.label||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={filterGeoArray(
            'state',
            states||[],
            undefined,
            warehouse.country
          )}
        />
      </Form.Item>
      
      <Form.Item
        label={'City'} 
        name={'city'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          onChange={(value, option:any) => setWarehouse({
            ...warehouse,
            city: option?.label||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={filterGeoArray(
            'city',
            cities||[],
            warehouse.state,
            warehouse.country
          )}
        />
      </Form.Item>

      <Form.Item
        label={'Zip Code'} 
        name={'zip_code'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setWarehouse({
            ...warehouse,
            zip_code: e.target.value
          })}
        />
      </Form.Item>

      <Form.Item>
        <Switch 
          checked={warehouse.is_active === 'y' ? true : false} 
          onChange={(e) => setWarehouse({
            ...warehouse,
            is_active: e ? 'y' : 'n'
          })}
        />
        <span style={{marginLeft: 15}}>
          Active Status
        </span>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isLoading}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  </BlankPage>
}